
@font-face {
    font-family: "NeueHaasDisplayRoman";
    src: url(./font/NeueHaasDisplayProFamily/NeueHaasDisplay-Roman.ttf);
}
@font-face {
    font-family: "NeueHaasDisplayBold";
    src: url(./font/NeueHaasDisplayProFamily/NeueHaasDisplay-Bold.ttf);
}
@font-face {
    font-family: "NeueHaasDisplayLight";
    src: url(./font/NeueHaasDisplayProFamily/NeueHaasDisplay-Light.ttf);
}
@font-face {
    font-family: "NeueHaasDisplayMedium";
    src: url(./font/NeueHaasDisplayProFamily/NeueHaasDisplay-Mediu.ttf)
}
@font-face {
    font-family: "HelveticaNeue";
    src: url(./font/HelveticaNeue/HelveticaNeueLight.ttf)
}
@font-face {
    font-family: "HelveticaNeuemedium";
    src: url(./font/HelveticaNeue/HelveticaNeueMedium.ttf)
}
@font-face {
    font-family: "HelveticaNeueRoman";
    src: url(./font/HelveticaNeueRoman.otf);
}
@font-face {
    font-family: "HelveticaNeueBold";
    src: url(./font/HelveticaNeueBold.otf);
}
iframe {
    width: 100%;
    height: 100%;
}

body,*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}
.body-content{
    padding: 1.75rem  1.75rem 0;
}
#firstScene{
    width: 100% !important;
    height: 100% !important;
}
.player-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.player-wrapper .react-player{
    height: 100%;
    position: absolute;
    width: 100%;
    /* bottom: -118px; */
}
video{
    object-fit: cover !important;
}

.player .vp-video-wrapper video{
    object-fit: cover !important;
}
.modal-video-body{
    max-width: 1200px;
}
h2{
    font-size: 38px;
    padding: 30px 0;
    color: #b1b1b1;
    letter-spacing: -0.03em;
}

h3{
    color: white;
    font-size: 35px;
}

span{
    font-family: "HelveticaNeue";
}

p{
    font-family: "HelveticaNeue";
    font-weight: 400;
    letter-spacing: -0.03em;
    font-size: 23px;
    line-height: 28px;
}

a{
    font-size: 17px;
    font-family: "HelveticaNeue";
    font-weight: 400;
    text-decoration: none;
    color: black;
}
:root {
    --video-width: 100vw;
    --video-height: 100vh;
}

@media (min-aspect-ratio: 16/9) {
    :root {
        --video-height: 56.25vw;
    }
}

@media (max-aspect-ratio: 16/9) {
    :root {
        --video-width: 177.78vh;
    }
}
.subrayado.philosophy_mobile{
    bottom: 35px;
    left: -15px;
}   
@media (max-width: 1024px) {
    .body-content{
        padding: 1rem;
    }
    .subrayado.philosophy_mobile{
        left: -10px;
        bottom: 0px;
    }
}
@media (max-width: 550px) {
    .body-content{
        padding: 0;
    }
    .subrayado.philosophy_mobile{
        left: -15px;
        bottom: 4px;
    }
}
.video-background {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.video-background iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--video-width);
    height: var(--video-height);
    transform: translate(-50%, -50%);
}
.show-enter{
    opacity: 0;
    transform: scale(0.9);
}
.show-enter-active {
    opacity: 0.5;
    transform: translateX(0);
    transition: opacity 0.1s, transform 0.5s;
}
.show-enter-done  {
    opacity: 1;
}
.show-exit {
    opacity: 1;
}
.show-exit-active {
    opacity: 0.5;
    transform: scale(0.9);
    transition: opacity 0.1s, transform 0.5s;
}
.show-exit-done {
    opacity: 0;
}
.subrayado{
    width: 202px;
    height: 10px;
    background-color: #FFFF02;
    position: absolute;
    bottom: 125px;
    left: 87px;
    transition: height 0.5s ease;
}
.subrayado.servicios{
    width: 232px;
    left: 15px;
    bottom: -29px;
}
.subrayado.left{
    left: -11px;
}
.subrayado.mobile{
   left: -10px;
   bottom: 42px;
}

.subrayado.morewidth{
    width: 270px;
}
.subrayado.lesswidth{
    width: 220px;
}
.subrayado.lesswidth_2{
    width: 150px;
}
.ocultar-error{
    display: none;
}
.error{
    display: flex;
    color: red !important;
    font-size: 15px !important;
}
@media (min-width: 1600px) {
    p{
        line-height: 31px;
    }
    a{
        font-size: 18px;
    }
    .player-wrapper .react-player{
        /* bottom: -50px; */
    }
}
@media (max-width: 1024px) {
    h2{
        font-size: 30px;
        padding: 10px 0;
    }
    h3{
        font-size: 30px;
    }

}
@media (max-width:550px) {
    .subrayado.servicios{
        left: -15px;
    }
    p{
        font-size: 18px;
        line-height: 21.47px;
    }
    a{
        font-size: 17px;
    }
    h2{
        font-size: 30px;
        padding: 10px 0;
    }
    h3{
        font-size: 30px;
    }
}
@media (max-width: 390px) {
    h2{
        font-size: 26px;
    }
    p{
        font-size: 17px;
        line-height: 20px;
    }
    .flex-column{
        flex-direction: column;
    }
}