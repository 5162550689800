:root {
  --link-1: #d65472;
  --link-2: #ffff02;
  --link-3: gold;
  --text: #18272f;
  --counter: #30b67d;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.studio-about {
  padding: 120px 100px;
  background-color: #434343;
  height: 600px;
  @media (max-width: 1024px) {
    padding: 40px;
    height: auto;
  }
  @media (max-width: 550px) {
    padding: 80px 54px 80px;
    margin: 15px 15px 0px;
  }
  @media (max-width: 390px) {
    padding: 80px 30px 80px;
  }
  @media (max-width: 360px) {
    padding: 80px 30px 80px;
  }
  .title {
    display: flex;
    h2 {
      padding: 0 0 25px 0;
      color: white;
      font-family: "HelveticaNeueBold";
      font-weight: 700;
      letter-spacing: -0.03em;
      display: flex;
      flex-direction: column;
      @media (max-width: 1024px) {
        font-size: 45px;
        padding: 25px 0 40px 0;
      }
      @media (max-width: 550px) {
        font-size: 35px;
        font-weight: 700;
        padding: 0px 0 40px 0;
      }
      &::after {
        content: " ";
        margin-top: 10px;
        width: 100px;
        height: 2px;
        background-color: white;
        @media (max-width: 1024px) {
          width: 130px;
          margin-top: 45px;
        }
        @media (max-width: 550px) {
          width: 95px;
          margin-top: 35px;
        }
      }
    }
  }
  p {
    color: white;
    width: 916px;
    letter-spacing: -0.03em;
    @media (min-width: 1600px) {
      //    width: 70%;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 550px) {
      font-size: 18px;
    }
    @media (max-width: 360px) {
      font-size: 16px;
    }
  }
}
.about-p {
  @media (max-width: 390px) {
    padding-bottom: 20px;
  }
  .about-description {
    @media (max-width: 550px) {
      font-size: 18px;
      padding-right: 35px;
    }
    @media (max-width: 390px) {
      font-size: 14px;
      padding-right: 55px;
      line-height: 18px;
    }
  }
}
.leadership {
  padding: 120px 100px;
  // height: 600px;
  background-color: #f1f0eb;
  @media (max-width: 1024px) {
    padding: 40px;
  }
  @media (max-width: 550px) {
    margin: 0px 15px 0px;
  }
  @media (max-width: 390px) {
    padding: 30px;
  }
  .title {
    display: flex;
    @media (max-width: 1024px) {
      justify-content: center;
      margin-top: 0px;
    }
    h2 {
      padding: 0 0 25px 0;
      color: black;
      font-family: "HelveticaNeueBold";
      font-weight: 700;
      letter-spacing: -0.03em;
      display: flex;
      flex-direction: column;
      @media (max-width: 1024px) {
        font-size: 45px;
      }
      @media (max-width: 550px) {
        font-size: 35px;
        padding: 0 0 15px 0;
      }
      @media (max-width: 390px) {
        font-size: 30px;
      }
      &::after {
        content: " ";
        margin-top: 10px;
        width: 100px;
        height: 2px;
        background-color: black;
        @media (max-width: 1024px) {
          margin: 50px 40px 0px;
          width: 130px;
        }
        @media (max-width: 550px) {
          margin: 35px 35px 0px;
          width: 95px;
        }
        @media (max-width: 390px) {
          margin: 35px 32px 0px;
          width: 80px;
        }
      }
    }
  }
  .cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 50px 0 0;
    @media (max-width: 1024px) {
      justify-content: space-evenly;
      flex-wrap: wrap;
      column-gap: 20px;
    }
    @media (max-width: 550px) {
      display: none;
    }
    .card {
      width: 15%;
      height: 100%;
      @media (max-width: 1024px) {
        width: 45%;
        padding-bottom: 30px;
      }

      &:nth-child(5) {
        padding-bottom: 0;
      }
      img {
        width: 100%;
        height: 270px;
        object-fit: cover;
        @media (min-width: 1600px) {
          height: 370px;
        }
        @media (max-width: 1024px) {
          height: 420px;
        }
        @media (max-width: 550px) {
          height: 200px;
        }
      }
      h3 {
        color: black;
        font-family: "HelveticaNeue";
        font-size: 25px;
        padding: 30px 0 0;
        @media (max-width: 550px) {
          font-size: 20px;
          padding: 20px 0 0;
        }
      }
      p {
        color: #6c6c6c;
        padding-top: 5px;
        @media (max-width: 550px) {
          font-size: 17px;
        }
      }
    }
  }
  &:nth-child(5) {
    padding-bottom: 0;
  }
  img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    @media (min-width: 1600px) {
      height: 370px;
    }
    @media (max-width: 1024px) {
      height: 420px;
    }
    @media (max-width: 550px) {
      height: 200px;
    }
  }
  h3 {
    color: black;
    font-family: "HelveticaNeue";
    font-size: 25px;
    padding: 30px 0 0;
    @media (max-width: 550px) {
      font-size: 20px;
      padding: 20px 0 0;
    }
  }
  p {
    color: #6c6c6c;
    padding-top: 5px;
    @media (max-width: 550px) {
      font-size: 17px;
    }
  }
}
.swiper-about {
  background-color: #f1f0eb;
  display: none;
  margin: 0px 15px 0px;
  @media (max-width: 550px) {
    display: block;
    padding-bottom: 80px;
  }
  @media (max-width: 390px) {
    padding-bottom: 60px;
  }
  .mySwiperAbout {
    padding-bottom: 70px;
    width: 100%;
    height: 380px;
    @media (max-width: 550px) {
      padding-bottom: 80px;
      height: 415px;
    }
    @media (max-width: 390px) {
      height: 370px;
    }
    .swiper-slide {
      width: 240px !important;
      margin-right: 25px !important;
      @media (max-width: 550px) {
        width: 250px !important;
      }
      @media (max-width: 390px) {
        width: 215px !important;
      }
      .card {
        width: 100%;
        height: 100%;
        margin-left: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        h3 {
          color: #000;
          font-family: "HelveticaNeueRoman";
          font-weight: 400;
          letter-spacing: -0.03em;
          font-size: 20px;
          padding: 20px 0 0;
          text-align: center;
          @media (max-width: 550px) {
            font-size: 17.5px;
          }
          @media (max-width: 390px) {
            font-size: 17.5px;
          }
        }
        p {
          color: #6c6c6c;
          padding-top: 5px;
          font-size: 17px;
          text-align: center;
          font-family: "HelveticaNeueRoman";
          letter-spacing: -0.03em;
          font-weight: 400;
          @media (max-width: 550px) {
            font-size: 18px;
          }
          @media (max-width: 390px) {
            font-size: 17px;
          }
        }
      }
    }
  }
}

.imagen-temp {
  // padding: 1.75rem  1.75rem 0;
  padding-bottom: 1.75rem;
  @media (max-width: 1024px) {
    padding-bottom: 1.5rem;
    height: 450px;
  }
  @media (max-width: 550px) {
    height: 350px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.top-gray {
  width: 100%;
  height: 100px;
  background-color: black;
}
.detalle_video {
  height: 100vh;
  background-color: black;
  @media (max-width: 1024px) {
    height: 60vh;
  }
}
.content_article {
  width: 100%;
  height: auto;
  padding: 120px 100px 50px;
  @media (max-width: 1024px) {
    padding: 50px 40px 0;
  }
  .title {
    display: flex;
    @media (max-width: 1024px) {
      display: none;
    }
    h2 {
      padding: 0 0 25px 0;
      color: black;
      font-family: "NeueHaasDisplayMedium";
      display: flex;
      flex-direction: column;
      font-weight: normal;
      &::after {
        content: " ";
        margin-top: 10px;
        width: 100px;
        height: 2px;
        background-color: black;
      }
    }
  }
  .content_text {
    color: #6c6c6c;
    a {
      // font-family: HelveticaNeuemedium;
      color: black !important;
      text-decoration: none;

      // border-bottom: 4px solid #FFFF02;
      font-size: 23px;
      @media (max-width: 550px) {
        font-size: 18px;
      }
      &::after {
        content: "";
        width: 0px;
        height: 2px;
        background-color: black;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: width 0.8s ease;
      }
      u {
        text-decoration: none;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
    ol {
      padding-left: 20px;
      li {
        font-family: "HelveticaNeue";
        font-size: 23px;
        @media (max-width: 550px) {
          font-size: 18px;
          line-height: 21.47px;
        }
      }
    }
  }
}
.contento-pagination {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #f1f0eb !important;
}

.pagination_boton {
  padding: 50px 100px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    padding: 20px 40px 60px;
  }
  a {
    font-family: HelveticaNeuemedium;
    font-size: 23px;
    font-weight: 700;
    // position: absolute;
    background-color: transparent;
    color: black;
    bottom: 0;
    z-index: 1;
    padding: 0 15px;
    cursor: pointer;
    @media (max-width: 550px) {
      font-size: 20px;
    }
    @media (max-width: 390px) {
      font-size: 19px;
    }
    &::after {
      content: " ";
      width: 100%;
      height: 15px;
      background-color: #ffff02;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      transition: height 0.5s ease-in-out;
    }
    &:hover {
      &::after {
        height: 20px;
      }
    }
  }
}
